<template>
  <div class="w-full flex flex-col">
    <consulta-component />
  </div>
</template>

<script>
import Consulta from '@/views/dashboard/user/doctor/ficha/Consulta.vue';

export default {
  name: 'Form',
  components: {
    'consulta-component': Consulta,
  },
};
</script>
